html,
body {
  margin: 0px;
  padding: 0px;
  font-size: 12px;

  /* Fix for IOS items disappearing on scroll */
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
  height: 100% !important;

  /* Prevent users from refreshing the page by pulling the page on mobile Chrome */
  overscroll-behavior: none;
}

/* To fix flashing of new shadcn dialog : https://github.com/shadcn-ui/ui/issues/1351 */
* {
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

#__next {
  height: 100%;
}

.ant-input {
  font-size: 1rem !important;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: none;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  @apply bg-brand-blue;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 3px;
  right: 0;
  padding-right: 6px;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  content: '';
  position: absolute;
  right: 6px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

body {
  @apply text-gray-800;
  @apply font-sans;
  @apply leading-normal;
  @apply select-none;
}

body > img {
  image-orientation: from-image !important;
}

body > a {
  text-decoration-line: none !important;
  color: inherit;
  display: block;
}

body > a:focus {
  outline: none !important;
}

/* fix old modal with new shadcn lego stuff remove when there is no more antd */
.ant-modal-wrap,
.ant-drawer,
.ant-modal-mask {
  z-index: 50 !important;
}

.ant-table-filter-dropdown {
  max-width: min(75vw, 64rem);
}
.ant-dropdown-menu-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media print {
  .print-none,
  .ant-menu,
  iframe,
  img,
  svg,
  button {
    display: none !important;
  }
  div,
  html,
  body {
    box-sizing: content-box !important;
    overflow: visible !important;
  }
  table {
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
}

/* Target specific file with tailwind classes */
/* The goal is to remove those, so don't add more please (August 2023) */

.item-event-item .ant-timeline-item-label {
  @apply absolute -left-44 -top-1 text-right;
}

.item-event-item .ant-timeline-item-content {
  @apply -top-1;
}

.timeline-item .ant-timeline-item-label {
  @apply pt-1 font-semibold;
}
.timeline-item .ant-timeline-item-content {
  @apply pt-1 font-semibold;
}

.project-and-organization .ant-tabs-tab {
  @apply px-4;
}
.project-and-organization-file-drawer-multi-tab-dropdown
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  @apply text-gray-900;
}
.project-and-organization-file-drawer-multi-tab-dropdown .ant-tabs-ink-bar {
  @apply bg-brand-blue;
}

.organization-page .ant-tabs-content {
  @apply h-full max-h-[90.5vh];
}

.project-inventory-view .ant-tabs-content {
  @apply h-full max-h-[90.5vh];
}

.multi-tab-dropdown .ant-tabs-nav {
  @apply ml-4;
}
.daily-summary .ant-carousel,
.slick-slider,
.slick-list,
.slick-track,
.slick-slide {
  @apply h-full;
}
.daily-summary .slick-slide > div {
  @apply h-full;
}
.daily-summary .slick-dots > li > button {
  @apply !bg-black;
}
.daily-summary .slick-dots {
  @apply h-[1rem];
}

.project-task-dialog .ant-modal-content {
  @apply static h-screen w-screen md:h-auto md:w-auto;
}
.project-task-dialog .ant-modal-body {
  @apply h-full w-full p-1 text-base;
}

.side-drawer .ant-drawer-content {
  @apply h-screen w-screen md:h-full md:w-full;
}
.side-drawer .ant-drawer-body {
  @apply flex flex-col overflow-hidden p-2;
}

.shift-dialog .ant-radio-group {
  margin-bottom: 8px;
}

.shift-dialog .ant-radio-button-wrapper {
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-radius: 0px;
  align-items: center;
}

.shift-dialog .ant-radio-button-wrapper span:nth-child(2) {
  display: inline-block;
  text-align: center;
  width: 64px;
}

.shift-dialog .ant-radio-button-wrapper::before {
  width: 0px;
}

.shift-dialog .ant-radio-button-wrapper:focus-within {
  box-shadow: none;
}

.daily-log-sms-dialog .ant-modal-content {
  @apply static;
}
.daily-log-sms-dialog .ant-modal-body {
  @apply flex w-full p-0;
}

.select-file-dialog .ant-modal-content {
  min-width: 0;
  width: 100%;
}
.select-file-dialog .ant-modal-wrap {
  @apply flex justify-center overflow-hidden;
}

.preview-dialog .ant-modal-content {
  @apply min-w-[85vw];
}

.preview-dialog-wrapper .ant-modal-wrap {
  @apply flex justify-center overflow-hidden;
}

.mini-daily-log .ant-modal-content {
  @apply static h-screen w-screen md:h-auto md:w-auto;
}
.mini-daily-log .ant-modal-body {
  @apply h-full w-full p-1 text-base;
}

.file-uploader-dialog-parent .ant-modal-wrap,
.file-uploader-dialog-parent .ant-image-preview-wrap {
  @apply overflow-hidden;
}

.file-uploader-dialog-modal .ant-modal-content {
  @apply min-w-[50vw];
}

.file-uploader-dialog-uploader-container .uppy-Dashboard-inner {
  @apply max-h-[50vh];
}

.dialog-dialog-container .ant-modal-wrap {
  @apply flex flex-none justify-center;
}

.dialog-dialog .ant-modal-body {
  @apply flex h-[100svh] w-full flex-none overflow-hidden text-base md:h-full;
}

.dialog-dialog .ant-modal-wrap {
  @apply overflow-hidden;
}
.dialog-dialog .ant-modal-content {
  @apply w-full;
}

.table-v2 .archived-row {
  @apply bg-gray-200 opacity-80;
}
.table-v2 .archived-row .ant-table-cell-fix-right {
  @apply bg-gray-200;
}
.table-v2 .archived-row td {
  @apply border-gray-400;
}

.table-v2 .ant-table-cell {
  @apply whitespace-normal;
}

.table-v2 .ant-table-header .ant-table-cell {
  @apply bg-white;
}

.table-v2 .ant-table-header th.ant-table-column-has-sorters:hover {
  @apply bg-gray-300;
}

.table-v2 .ant-table-header .ant-table-column-sort {
  @apply bg-gray-300;
}

.table-v2 .ant-table-column-sort {
  @apply bg-none;
}

.table-v2 .ant-table-body::-webkit-scrollbar {
  @apply h-3 w-3;
}

.table-v2 .ant-table-body::-webkit-scrollbar-thumb {
  @apply rounded-2xl bg-[#bac1cd];
}

.table-v2 .ant-table-body::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-400;
}

.compare-to-dialog .ant-modal-content {
  @apply w-full min-w-0;
}
.compare-to-dialog .ant-modal-wrap {
  @apply flex justify-center overflow-hidden;
}

.move-to-dialog .ant-modal-content {
  @apply w-full min-w-0;
}
.move-to-dialog .ant-modal-wrap {
  @apply flex justify-center overflow-hidden;
}

.location-form .mapboxgl-ctrl {
  @apply flex min-w-full items-center;
}
.location-form .mapboxgl-ctrl input {
  @apply h-10 w-full rounded border text-base shadow focus:outline-none;
}
.location-form .mapboxgl-ctrl svg {
  @apply left-[8px] top-[6px];
}
.location-form .mapboxgl-ctrl-geocoder--button {
  @apply top-[5px];
}

.file-explorer-v2 {
  @apply flex;
  height: calc(96% - 90px);
  @media (min-height: 1080px) {
    height: calc(96% - 68px);
  }
  @media (min-height: 760px) {
    height: calc(96% - 80px);
  }
}

.event-all-slider > *::-webkit-slider-thumb {
  pointer-events: all;
}
.event-all-slider > *::-moz-range-thumb {
  pointer-events: all;
}

.draft-text-editor .DraftEditor-root {
  @apply text-lg;
}

.draft-text-editor .public-DraftEditorPlaceholder-root {
  @apply absolute z-0 text-gray-500;
}

.draft-text-editor .public-DraftEditorPlaceholder-hasFocus {
  @apply text-gray-500;
}

.draft-text-editor .DraftEditorPlaceholder-hidden {
  @apply hidden;
}

.draft-text-editor .public-DraftStyleDefault-ol {
  @apply m-0;
}
.draft-text-editor .public-DraftStyleDefault-ul {
  @apply m-0;
}

.draft-text-editor #mention-container > div {
  @apply max-h-64 overflow-y-auto;
}

.icon-color > svg {
  @apply h-full w-full;
  color: var(--color-icon);
}

.icon-color-hover:hover > svg {
  color: var(--color-icon-hover);
}

.simplebar-track {
  background-color: #f1f5f9; /* bg-gray-100 */
  border-top: 1px solid #cbd5e1; /* border-t border-gray-100 */
  border-bottom: 1px solid #cbd5e1; /* border-b border-gray-100 */
  padding-bottom: 1px;
  display: flex;
  align-items: center;
}

.simplebar-scrollbar {
  border-radius: 6px; /* rounded-md */
  padding-bottom: 2px;
}

.simplebar-scrollbar:before {
  background-color: #a0aec0 !important; /* bg-gray-400 */
}

.tiptap ul[data-type='taskList'] {
  padding-left: 0;
}
.tiptap ul[data-type='taskList'] li {
  align-items: flex-start;
  display: flex;
  column-gap: 0.5rem;
}

.tiptap ::selection {
  color: white;
  background-color: #3b82f6;
}

.ProseMirror-selectednode ::selection {
  color: white;
  background-color: #3b82f6;
}

.tiptap a {
  color: #2441c5;
}

.tiptap a:hover {
  text-decoration-line: underline;
}

.tiptap img.ProseMirror-selectednode {
  border: 1px solid #3b82f6;
}

.tiptap .mention {
  background-color: var(--purple-light);
  border-radius: 0.4rem;
  box-decoration-break: clone;
  color: var(--purple);
  padding: 0.1rem 0.3rem;
  &::after {
    content: '\200B';
  }
}

.node-filenode ::selection {
  color: black;
  background-color: transparent;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

/* Target specific file with tailwind classes */
/* The goal is to remove those, so don't add more please (August 2023) */
